import {Nav, Navbar} from "@govtechsg/sgds-react";
import {Link} from "react-router-dom";
import logo from "../images/logo-white.png";
import React, {useState} from "react";
import {useMsal} from "@azure/msal-react";

export default function CustomNavbar({
                                         currentActiveNav = "home",
                                         openSidebar,
                                         setOpenSidebar,
                                         openRightbar,
                                         setOpenRightbar,
                                         ...props
                                     }) {
    const {instance, inProgress, accounts} = useMsal();
    const [showDropdown, setShowDropdown] = useState(false);

    const roleList = JSON.parse(sessionStorage.getItem("roles")) || [];

    return (
        <>
            <Navbar
                className={`align-items-center justify-content-between px-2 px-md-8`}
            >
                <Navbar.Brand onClick={() => window.location.href = "/"}>
                    <img src={logo}/>
                </Navbar.Brand>

                <h1 className="text-blue title-middle">
                    Financial Assistance Administration System
                </h1>
                <div className="p-relative">
                    <div
                        onClick={() => setShowDropdown((prev) => !prev)}
                        className={`d-flex gap-3 align-items-center p-relative cursor-pointer`}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                        >
                            <circle cx="24" cy="24" r="24" fill="#D9D9D9"/>
                        </svg>
                        <span className="d-none d-lg-block">
              {" "}
                            {accounts.length > 0 ? accounts[0]?.username : ""}
            </span>
                    </div>
                    <div className={`card-dropdown ${showDropdown ? "show" : ""}`}>
            <span className="d-block d-lg-none">
              {accounts.length > 0 ? accounts[0]?.username : ""}
            </span>
                        <Link
                            href="#"
                            onClick={() => {
                                sessionStorage.removeItem("idToken");
                                instance.logoutRedirect();
                            }}
                        >
                            Logout
                        </Link>
                    </div>
                </div>
            </Navbar>

            <Navbar
                expand="lg"
                className={`bg-blue text-right`}
                style={{minHeight: "fit-content"}}
            >
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    className={`align-items-center justify-content-center `}
                >
                    <Nav className="mx-auto d-flex gap-5" navbarScroll>
                        {/* <Nav.Item>
                            <Nav.Link
                                className={`custom-nav`}
                                eventKey='home'
                                onClick={() => setOpenSidebar(!openSidebar)}
                            >
                                <i className='bi bi-list'></i>
                            </Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                            <Nav.Link
                                className={`custom-nav ${
                                    currentActiveNav == "home" ? "selected" : ""
                                }`}
                                eventKey="home"
                                onClick={() => window.location.href = "/"}
                            >
                                Home
                            </Nav.Link>
                        </Nav.Item>
                        {
                            roleList.includes("FAAS_ADM") && (
                                <Nav.Item>
                                    <Nav.Link
                                        className={`custom-nav ${
                                            currentActiveNav == "preparation" ? "selected" : ""
                                        }`}
                                        eventKey="home"
                                        onClick={() => window.location.href = "/preparation/scheme-detail"}
                                    >
                                        Application Preparation
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        }

                        {
                            (roleList.includes("FAAS_ADM") || roleList.includes("FAAS_TEMP") || roleList.includes("FAAS_TEMPA") || roleList.includes("FAAS_VIEW")) && (
                                <Nav.Item>
                                    <Nav.Link
                                        className={`custom-nav ${
                                            currentActiveNav == "app-records" ? "selected" : ""
                                        }`}
                                        eventKey="link"
                                        onClick={() => {
                                            if (roleList.length === 1 && roleList[0] === 'FAAS_VIEW') {
                                                window.location.href = "/app-records/app-enquiry"
                                            } else {
                                                window.location.href = "/app-records/app-maintenance"
                                            }
                                        }}
                                    >
                                        Administer Application Records
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        }

                        {
                            roleList.includes("FAAS_ADM") && (
                                <Nav.Item>
                                    <Nav.Link
                                        className={`custom-nav ${
                                            currentActiveNav == "processing" ? "selected" : ""
                                        }`}
                                        eventKey="link"
                                        onClick={() => window.location.href = "/processing/criteria"}
                                    >
                                        Application Processing
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        }

                        {
                            (roleList.includes("FAAS_ADM") || roleList.includes("FAAS_VIEW")) && (
                                <Nav.Item>
                                    <Nav.Link
                                        className={`custom-nav ${
                                            currentActiveNav == "reports" ? "selected" : ""
                                        }`}
                                        eventKey="link"
                                        onClick={() => window.location.href = "/reports/print-application-detail"}
                                    >
                                        Reports
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        }

                        {
                            (roleList.includes("FAAS_ADM") || roleList.includes("FAAS_TEMP") || roleList.includes("FAAS_TEMPA") || roleList.includes("FAAS_SU") || roleList.includes("FAAS_AWARD")) && (
                                <Nav.Item>
                                    <Nav.Link
                                        className={`custom-nav ${
                                            currentActiveNav == "utilities" ? "selected" : ""
                                        }`}
                                        eventKey="link"
                                        onClick={() => {
                                            if (roleList.includes("FAAS_ADM")) {
                                                window.location.href = "/utilities/update-scholarship/individual"
                                            }

                                            if (!roleList.includes("FAAS_ADM") && (roleList.includes("FAAS_TEMP") || roleList.includes("FAAS_TEMPA"))) {
                                                window.location.href = "/utilities/enquire-student-personal"
                                            }

                                            if (roleList.length === 1 && (roleList.includes("FAAS_VIEW") || roleList.includes("FAAS_SU"))) {
                                                window.location.href = "/utilities/system-administration"
                                            }
                                            if(roleList.includes("FAAS_AWARD")) {
                                                window.location.href = "/utilities/update-scholarship/add"
                                            }
                                        }
                                        }
                                    >
                                        Utilities
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}
