import React from "react";
import {Button, Col, Form, Row} from "@govtechsg/sgds-react";
import Select from "react-select";

function FormFilterHomes({
                             contentRef,
                             isOpen,
                             listAcadYears,
                             listExercise,
                             listAdmNo,
                             handleSubmit,
                             handleAcademicYearChange,
                             handleListExerciseChange,
                             handleListAdmNoChange,
                             academicYear,
                             selectedListExercise,
                             selectedListAdmNo,
                             handleSearch,
                             handleGetListAdminNo
                         }) {
    return (
        <Form
            ref={contentRef}
            className={` d-flex flex-column gap-3 collapse-animation`}
            style={{
                overflow: isOpen ? "unset" : "hidden",
                maxHeight: isOpen
                    ? `${
                        contentRef?.current?.scrollHeight == null
                            ? "100%"
                            : contentRef?.current?.scrollHeight
                    }px`
                    : "0px",
            }}

            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            <Row>
                <Col xs={3}>
                    <Form.Group>
                        <Form.Label>Academic Year<span className={`text-danger`}> *</span></Form.Label>
                        <Select
                            value={{label: academicYear, value: academicYear}}
                            options={listAcadYears}
                            onChange={(e) => {
                                handleAcademicYearChange(e);
                            }}
                        />
                    </Form.Group>
                </Col>

                <Col xs={3}>
                    <Form.Group>
                        <Form.Label>Exercise<span className={`text-danger`}> *</span></Form.Label>
                        <Select
                            options={listExercise}
                            value={{
                                label: selectedListExercise,
                                value: selectedListExercise,
                            }}
                            onChange={(e) => handleListExerciseChange(e)}
                        />
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <Form.Group>
                        <Form.Label>Adm. No<span className={`text-danger`}> *</span></Form.Label>
                        <Select
                            options={listAdmNo}
                            value={{
                                label: selectedListAdmNo,
                                value: selectedListAdmNo,
                            }}
                            onChange={(e) => handleListAdmNoChange(e)}
                            onInputChange={(value) =>
                                handleSearch(handleGetListAdminNo, value)
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={3}>
                    <Button type="submit" className="btn btn-primary">
                        Retrieve
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default FormFilterHomes;
