import {SideNav} from "@govtechsg/sgds-react";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";

export default function Sidebar(props) {
    const navigate = useNavigate();
    const pathname = useLocation().pathname;

    const [activeKey, setActiveKey] = useState([]);
    const [activeLinkKey, setActiveLinkKey] = useState("");

    const roleList = JSON.parse(sessionStorage.getItem("roles")) || [];

    const clickLink = (key) => {
        setActiveLinkKey(key);
    };

    const clickButton = (key) => {
        activeKey === key ? setActiveKey("") : setActiveKey(key);
    };

    const clickButtonLink = (key) => {
        setActiveLinkKey("");
        clickButton(key);
    };

    useEffect(() => {
        if (pathname.includes("/utilities/update-scholarship/individual")) {
            setActiveKey(["25"]);
            setActiveLinkKey("25-1");
        }

        if (pathname.includes("/utilities/update-scholarship/batch")) {
            setActiveKey(["25"]);
            setActiveLinkKey("25-2");
        }

        if (pathname.includes("/utilities/update-scholarship/add")) {
            setActiveKey(["25"]);
            setActiveLinkKey("25-3");
        }

        if (pathname.includes("/comcare/upload")) {
            setActiveKey(["25"]);
            setActiveLinkKey("25-2");
        }
    }, []);

    return (
        <div className="outer-left-sidebar p-relative">
            <div
                className="left-sidebar__chevron"
                onClick={() => props.setOpenSidebar(!props.openSidebar)}
            >
                {props.openSidebar ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                        />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                        />
                    </svg>
                )}
            </div>
            <sgds-aside-area
                style={{visibility: props.visibilityValue}}
                class={`left-sidebar`}
            >
                <SideNav
                    defaultActiveKey={["4"]}
                    activeKey={activeKey}
                    activeNavLinkKey={activeLinkKey}
                    alwaysOpen={true}
                >
                    {pathname.includes("/preparation") && roleList.includes("FAAS_ADM") ? (
                        <>
                            <SideNav.Item eventKey={"1"}>
                                <SideNav.Link
                                    eventKey={"1"}
                                    className={`custom-link ${
                                        pathname.includes("/scheme-detail") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/preparation/scheme-detail")}
                                >
                                    Setup Scheme Detail
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"2"}>
                                <SideNav.Link
                                    eventKey={"2"}
                                    className={`custom-link ${
                                        pathname.includes("/award-detail") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/preparation/award-detail")}
                                >
                                    Setup Award Detail
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"4"}>
                                <SideNav.Link
                                    eventKey={"4"}
                                    className={`custom-link ${
                                        pathname.includes("/setup-cspc") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/preparation/setup-cspc")}
                                >
                                    Setup Constituency Secretariat & Postal Code Mapping
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"5"}>
                                <SideNav.Link
                                    eventKey={"5"}
                                    className={`custom-link ${
                                        pathname.includes("/preparation/remarks") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/preparation/remarks/pa")}
                                >
                                    Setup Application Status Remarks (PA & NY)
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"7"}>
                                <SideNav.Link
                                    eventKey={"7"}
                                    className={`custom-link ${
                                        pathname.includes("/setup-occupation") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/preparation/setup-occupation")}
                                >
                                    Setup Occupation
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"8"}>
                                <SideNav.Link
                                    eventKey={"8"}
                                    className={`custom-link ${
                                        pathname.includes("/setup-relationship") ? "active" : null
                                    }`}
                                    onClick={() =>
                                        navigate("/preparation/setup-relationship/dialect")
                                    }
                                >
                                    Setup Dialect Grp/ Marital / Relationship Status
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"9"}>
                                <SideNav.Link
                                    eventKey={"9"}
                                    className={`custom-link  ${
                                        pathname.includes("/setup-timeline") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/preparation/setup-timeline")}
                                >
                                    Setup Application Timeline
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"10"}>
                                <SideNav.Link
                                    eventKey={"10"}
                                    className={`custom-link  ${
                                        pathname.includes("/comcare") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/preparation/comcare")}
                                >
                                    Maintain and Upload Comcare
                                </SideNav.Link>
                            </SideNav.Item>
                        </>
                    ) : null}
                    {pathname.includes("/app-records") && (roleList.includes("FAAS_ADM") || roleList.includes("FAAS_TEMP") || roleList.includes("FAAS_TEMPA") || roleList.includes("FAAS_VIEW")) ? (
                        <>
                           {
                                (roleList.includes("FAAS_ADM") ||
                                    roleList.includes("FAAS_TEMP") ||
                                    roleList.includes("FAAS_TEMPA")) && (
                                    <>
                                        <SideNav.Item eventKey={"10"}>
                                        <SideNav.Link
                                            eventKey={"10"}
                                            className={`custom-link ${
                                            pathname.includes("/app-maintenance") ? "active" : ""
                                            }`}
                                            onClick={() => navigate("/app-records/app-maintenance")}
                                        >
                                            Application Maintenance
                                        </SideNav.Link>
                                        </SideNav.Item>

                                        <SideNav.Item eventKey={"11"}>
                                        <SideNav.Link
                                            eventKey={"11"}
                                            className={`custom-link ${
                                            pathname.includes("/app-verification") ? "active" : ""
                                            }`}
                                            onClick={() => navigate("/app-records/app-verification")}
                                        >
                                            Application Verification
                                        </SideNav.Link>
                                        </SideNav.Item>
                                    </>
                                )
                            }

                            <SideNav.Item eventKey={"12"}>
                                <SideNav.Link
                                    eventKey={"12"}
                                    className={`custom-link ${
                                        pathname.includes("/app-enquiry") ? "active" : ""
                                    }`}
                                    onClick={() => navigate("/app-records/app-enquiry")}
                                >
                                    Application Enquiry
                                </SideNav.Link>
                            </SideNav.Item>

                            {
                                (roleList.includes("FAAS_ADM") ||
                                    roleList.includes("FAAS_TEMP") ||
                                    roleList.includes("FAAS_TEMPA"))
                                    && (
                                    <SideNav.Item eventKey={"13"}>
                                        <SideNav.Link
                                            eventKey={"13"}
                                            className={`custom-link ${
                                                pathname.includes("/app-homes") ? "active" : ""
                                            }`}
                                            onClick={() => navigate("/app-records/app-homes")}
                                        >
                                            Application HOMES
                                        </SideNav.Link>
                                    </SideNav.Item>
                                    )
                            }
                        </>
                    ) : null}

                    {pathname.includes("/processing") && roleList.includes("FAAS_ADM") && (
                        <>
                            <SideNav.Item eventKey={"14"}>
                                <SideNav.Link
                                    eventKey={"14"}
                                    className={`custom-link  ${
                                        pathname.includes("/processing/criteria") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/processing/criteria")}
                                >
                                    Setup Recommendation Criteria
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"15"}>
                                <SideNav.Link
                                    eventKey={"15"}
                                    className={`custom-link  ${
                                        pathname.includes("/processing/application")
                                            ? "active"
                                            : null
                                    }`}
                                    onClick={() => navigate("/processing/application")}
                                >
                                    Application Recommendation
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"16"}>
                                <SideNav.Link
                                    eventKey={"16"}
                                    className={`custom-link  ${
                                        pathname.includes("/processing/unmatched-cspc")
                                            ? "active"
                                            : null
                                    }`}
                                    onClick={() => navigate("/processing/unmatched-cspc")}
                                >
                                    List of Unmatched Postal Code & CS Code
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"17"}>
                                <SideNav.Link
                                    eventKey={"17"}
                                    className={`custom-link  ${
                                        pathname.includes("/processing/download") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/processing/download")}
                                >
                                    Download Recommended List
                                </SideNav.Link>
                            </SideNav.Item>
                            {/*
            <SideNav.Item eventKey={"18"}>
              <SideNav.Link
                eventKey={"18"}
                className={`custom-link`}
                onClick={() => navigate("/")}
              >
                Upload Recommended List
              </SideNav.Link>
            </SideNav.Item>

            */}
                            <SideNav.Item eventKey={"19"}>
                                <SideNav.Link
                                    eventKey={"19"}
                                    className={`custom-link  ${
                                        pathname.includes("/processing/batch-update")
                                            ? "active"
                                            : null
                                    }`}
                                    onClick={() => navigate("/processing/batch-update")}
                                >
                                    Batch Application Status Update
                                </SideNav.Link>
                            </SideNav.Item>
                        </>
                    )}

                    {pathname.includes("/reports") && (roleList.includes("FAAS_ADM") || roleList.includes("FAAS_VIEW")) && (
                        <>
                            <SideNav.Item eventKey={"20"}>
                                <SideNav.Link
                                    eventKey={"20"}
                                    className={`custom-link  ${
                                        pathname.includes("/reports/print-application-detail")
                                            ? "active"
                                            : null
                                    }`}
                                    onClick={() => navigate("/reports/print-application-detail")}
                                >
                                    Print Applicants Detail
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"21"}>
                                <SideNav.Link
                                    eventKey={"21"}
                                    className={`custom-link  ${
                                        pathname.includes("/reports/print-shortlisted-applicants")
                                            ? "active"
                                            : null
                                    }`}
                                    onClick={() =>
                                        navigate("/reports/print-shortlisted-applicants")
                                    }
                                >
                                    Print Shortlisted Applicants
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"22"}>
                                <SideNav.Link
                                    eventKey={"22"}
                                    className={`custom-link  ${
                                        pathname.includes("/reports/print-application-summary")
                                            ? "active"
                                            : null
                                    }`}
                                    onClick={() => navigate("/reports/print-application-summary")}
                                >
                                    Print Application Summary
                                </SideNav.Link>
                            </SideNav.Item>

                            <SideNav.Item eventKey={"23"}>
                                <SideNav.Link
                                    eventKey={"23"}
                                    className={`custom-link  ${
                                        pathname.includes("/reports/print-recommended-applicants")
                                            ? "active"
                                            : null
                                    }`}
                                    onClick={() =>
                                        navigate("/reports/print-recommended-applicants")
                                    }
                                >
                                    Print Recommended Applicants
                                </SideNav.Link>
                            </SideNav.Item>

                            {/*
              <SideNav.Item eventKey={"24"}>
                <SideNav.Link
                  eventKey={"24"}
                  className={`custom-link  ${
                    pathname.includes("/reports/sample") ? "active" : null
                  }`}
                  onClick={() => navigate("/reports/sample")}
                >
                  Sample
                </SideNav.Link>
              </SideNav.Item>
              <SideNav.Item eventKey={"25"}>
                <SideNav.Link
                  eventKey={"25"}
                  className={`custom-link  ${
                    pathname.includes("/reports/second-sample")
                      ? "active"
                      : null
                  }`}
                  onClick={() => navigate("/reports/second-sample")}
                >
                  Second Sample
                </SideNav.Link>
              </SideNav.Item>
              <SideNav.Item eventKey={"26"}>
                <SideNav.Link
                  eventKey={"26"}
                  className={`custom-link  ${
                    pathname.includes("/reports/third-sample") ? "active" : null
                  }`}
                  onClick={() => navigate("/reports/third-sample")}
                >
                  Third Sample
                </SideNav.Link>
              </SideNav.Item>

              */}
                            {/*
            <SideNav.Item eventKey={"24"}>
              <SideNav.Link
                eventKey={"24"}
                className={`custom-link ${
                  pathname.includes("/reports/print-ghi-application-form")
                    ? "active"
                    : null
                }`}
                onClick={() => navigate("/reports/print-ghi-application-form")}
              >
                Print GHI Application Form
              </SideNav.Link>
            </SideNav.Item>

            */}
                        </>
                    )}

                    {pathname.includes("/utilities") && (roleList.includes("FAAS_ADM") || roleList.includes("FAAS_TEMP") || roleList.includes("FAAS_TEMPA") || roleList.includes("FAAS_SU") || roleList.includes("FAAS_AWARD")) && (
                        <>
                            {
                                (roleList.includes("FAAS_ADM") || roleList.includes("FAAS_AWARD")) && (
                                    <SideNav.Item eventKey={"25"}>
                                        <SideNav.Button
                                            eventKey={"25"}
                                            className={`custom-link  ${
                                                pathname.includes("/utilities/update-scholarship")
                                                    ? "active"
                                                    : null
                                            }`}
                                            onClick={() => clickButton("25")}
                                        >
                                            Update Student Scholarship Detail
                                        </SideNav.Button>
                                        <SideNav.Collapse>
                                            <SideNav.Link
                                                eventKey="25-1"
                                                className={`custom-link  ${
                                                    pathname.includes(
                                                        "/utilities/update-scholarship/individual"
                                                    )
                                                        ? "active"
                                                        : null
                                                }`}
                                                onClick={() =>
                                                    navigate(`/utilities/update-scholarship/individual`)
                                                }
                                            >
                                                Individual
                                            </SideNav.Link>
                                            <SideNav.Link
                                                eventKey="25-2"
                                                className={`custom-link  ${
                                                    pathname.includes("/utilities/update-scholarship/batch")
                                                        ? "active"
                                                        : null
                                                }`}
                                                onClick={() =>
                                                    navigate(`/utilities/update-scholarship/batch`)
                                                }
                                            >
                                                Batch
                                            </SideNav.Link>
                                            <SideNav.Link
                                                eventKey="25-3"
                                                className={`custom-link  ${
                                                    pathname.includes("/utilities/update-scholarship/add")
                                                        ? "active"
                                                        : null
                                                }`}
                                                onClick={() =>
                                                    navigate(`/utilities/update-scholarship/add`)
                                                }
                                            >
                                                Add Awards
                                            </SideNav.Link>
                                        </SideNav.Collapse>
                                    </SideNav.Item>
                                )
                            }

                            {
                                (roleList.includes("FAAS_ADM") || roleList.includes("FAAS_TEMP") || roleList.includes("FAAS_TEMPA")) && (
                                    <SideNav.Item eventKey={"26"}>
                                        <SideNav.Link
                                            eventKey={"26"}
                                            className={`custom-link  ${
                                                pathname.includes("/utilities/enquire-student-personal")
                                                    ? "active"
                                                    : null
                                            }`}
                                            onClick={() =>
                                                navigate("/utilities/enquire-student-personal")
                                            }
                                        >
                                            Enquire Student Personal Particulars
                                        </SideNav.Link>
                                    </SideNav.Item>
                                )
                            }

                            {
                                roleList.includes("FAAS_SU") && (

                                    <SideNav.Item eventKey={"27"}>
                                        <SideNav.Link
                                            eventKey={"27"}
                                            className={`custom-link  ${
                                                pathname.includes("/utilities/system-administration")
                                                    ? "active"
                                                    : null
                                            }`}
                                            onClick={() => navigate("/utilities/system-administration")}
                                        >
                                            System Administration
                                        </SideNav.Link>
                                    </SideNav.Item>
                                )
                            }

                            <SideNav.Item eventKey={"28"}>
                                <SideNav.Link
                                    eventKey={"28"}
                                    className={`custom-link  ${
                                        pathname.includes("/utilities/upload") ? "active" : null
                                    }`}
                                    onClick={() => navigate("/utilities/upload")}
                                >
                                    Upload Student File
                                </SideNav.Link>
                            </SideNav.Item>
                        </>
                    )}
                </SideNav>
            </sgds-aside-area>
        </div>
    );
}
