import React, {useEffect, useState} from "react";

import {Button, Col, Form, FormCheck, Row} from "@govtechsg/sgds-react";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
];

function GovernmentBursary({
                               isDisabled,
                               scholarshipDetail,
                               setListScholarship,
                               handleChangeListScholarship,
                               tobeDeletedScholar,
                               handleChangeTobeDeleted,
                               data,
                               isReadOnly = false,
                           }) {
    const [isScholarship, setIsScholarship] = useState(false);

    const handleAddNewScholarship = () => {
        setListScholarship([
            ...scholarshipDetail,
            {
                scholar_sponsor: "",
                tutfee_c_tag: "",
                l_allowance_c_tag: "",
                l_allowance_c_details: "0",
                l_allowance_c_type: "",
                others_c_tag: "",
                others_c_details: "",
                rec_tag: "B",
                govbur_tag: "Y",
            },
        ]);
    };

    const handleRemoveNewScholarship = (id, index) => {
        if (index == 0) {
            toast.error("Cannot delete the first scholarship");
            return;
        }

        //if id
        if (id) {
            //push to array state tobeDeletedScholar
            handleChangeTobeDeleted([...tobeDeletedScholar, id]);
        }
        const updatedScholarships = [...scholarshipDetail];
        setListScholarship(updatedScholarships.filter((_, i) => i !== index));
    };

    const handleScholarshipRadio = (e) => {
        if (e.target.value == "yes") {
            setIsScholarship(true);
        } else {
            setIsScholarship(false);
        }
    };

    useEffect(() => {
        if (scholarshipDetail?.some(detail => detail.rec_tag === "B")) {
            setIsScholarship(true);
        }

        if (data?.govbur_tag === "Y") {
            setIsScholarship(true);
        }

    }, [data, scholarshipDetail]);

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>
                                I am receiving a government bursary in the current academic year
                            </Form.Label>
                            <FormCheck
                                id="option-gov-1"
                                label="Yes"
                                name="radio_checks1"
                                type="radio"
                                value="yes"
                                checked={isScholarship == true}
                                onChange={handleScholarshipRadio}
                                disabled={isDisabled}
                            />
                            <FormCheck
                                id="option-gov-2"
                                label="No"
                                name="radio_checks1"
                                type="radio"
                                value="no"
                                checked={isScholarship == false}
                                onChange={handleScholarshipRadio}
                                disabled={isDisabled}
                            />
                        </Form.Group>
                    </Col>
                    {isScholarship ? (
                        <React.Fragment>
                            <div>
                                {isReadOnly ? (
                                    ""
                                ) : (
                                    <Button
                                        type="button"
                                        disabled={isDisabled}
                                        variant="btn btn-link ps-0 text-decoration-none"
                                        onClick={() => handleAddNewScholarship()}
                                    >
                                        <i class="bi bi-plus-circle"></i> {"  "} Add government
                                        bursary
                                    </Button>
                                )}
                            </div>
                            {scholarshipDetail?.map((item, index) => {
                                if (item?.rec_tag == "B") {
                                    return (
                                        <div className="mt-3" key={index}>
                                            <h4>
                                                Government Bursary
                                                {isReadOnly ? (
                                                    ""
                                                ) : (
                                                    <button
                                                        className={`btn-clear`}
                                                        type={"button"}
                                                        disabled={isDisabled}
                                                        onClick={() => {
                                                            handleRemoveNewScholarship(item.id, index);
                                                        }}
                                                    >
                                                        <i className={`bi bi-trash`}></i>
                                                    </button>
                                                )}
                                            </h4>
                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Group>
                                                        <Form.Label>Name of Government Bursary</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={item?.scholar_sponsor ?? ""}
                                                            onChange={(e) =>
                                                                handleChangeListScholarship(
                                                                    "scholar_sponsor",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        {" "}
                                                        <Form.Label>Quantum</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={item?.govbur_quantum ?? ""}
                                                            onChange={(e) =>
                                                                handleChangeListScholarship(
                                                                    "govbur_quantum",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group className={`d-flex flex-column`}>
                                                        {" "}
                                                        <Form.Label>Period</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={item?.govbur_period ?? ""}
                                                            onChange={(e) =>
                                                                handleChangeListScholarship(
                                                                    "govbur_period",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        ></Form.Control>

                                                        {/*<DatePicker*/}
                                                        {/*    selected={*/}
                                                        {/*        item?.govbur_period*/}
                                                        {/*            ? moment(*/}
                                                        {/*                item.govbur_period*/}
                                                        {/*            ).toDate()*/}
                                                        {/*            : null*/}
                                                        {/*    }*/}
                                                        {/*    className={`form-control`}*/}
                                                        {/*    onChange={(date) => {*/}
                                                        {/*        if (date == null) {*/}
                                                        {/*            handleChangeListScholarship(*/}
                                                        {/*                "govbur_period",*/}
                                                        {/*                "",*/}
                                                        {/*                index*/}
                                                        {/*            );*/}
                                                        {/*        } else {*/}
                                                        {/*            handleChangeListScholarship(*/}
                                                        {/*                "govbur_period",*/}
                                                        {/*                moment(date).format("MM/YYYY"),*/}
                                                        {/*                index*/}
                                                        {/*            );*/}
                                                        {/*        }*/}
                                                        {/*    }}*/}
                                                        {/*    dateFormat="MM/YYYY"*/}
                                                        {/*    showMonthDropdown*/}
                                                        {/*    showYearDropdown*/}
                                                        {/*    disabled={isDisabled}*/}
                                                        {/*/>*/}

                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                }
                            })}
                        </React.Fragment>
                    ) : null}
                </Row>
            </div>
        </React.Fragment>
    );
}

export default GovernmentBursary;
