import { apiCall } from "@/utils/apiCall";
import { createUrlWithParams } from "@/components/utils/utils";

// NYP

// {
//     "CODE": "10",
//     "REMARK": "need confirmations"
// }

export async function getRemarkNYP(limit = 20, page = 1, code = null, remark="") {
  let params = {};
  if (code !== null && code !== "" && code !== undefined) {
    params = {
      ...params,
      code: code,
    };
  }
  params = {
    ...params,
    limit: limit,
    remark: remark,
    page: page,
  };

  let baseUrl = `${process.env.REACT_APP_API_URL}/applications/remarks/nyp`;
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(url, {}, "GET");
}

export function postRemarkNYP(remarkData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp`,
    remarkData,
    "POST"
  );
}

export function putRemarkNYP(remarkID, remarkData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp/${remarkID}`,
    remarkData,
    "PUT"
  );
}

export function deleteRemarkNYP(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp/${selectedId}`,
    {},
    "DELETE"
  );
}

export function postRemarkNYPOverwrite(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp/overwrite`,
    {
      data: jsonData,
    },
    "POST"
  );
}

export function postRemarkNYPAppend(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/nyp/append`,
    {
      data: jsonData,
    },
    "POST"
  );
}

// PA

// {
//     "STS_REASON": "withdraw"
// }

export async function getRemarkPA(limit = 20, page = 1, search = "") {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa?limit=${limit}&page=${page}&sts_reason=${search}`,
    {},
    "GET"
  );
}

export function postRemarkPA(remarkData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa`,
    remarkData,
    "POST"
  );
}

export function putRemarkPA(remarkID, remarkData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa/${remarkID}`,
    remarkData,
    "PUT"
  );
}

export function deleteRemarkPA(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa/${selectedId}`,
    {},
    "DELETE"
  );
}

export function postRemarkPAOverwrite(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa/overwrite`,
    {
      data: jsonData,
    },
    "POST"
  );
}

export function postRemarkPAAppend(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/remarks/pa/append`,
    {
      data: jsonData,
    },
    "POST"
  );
}
