import React, {useEffect, useState} from "react";
import {Col, Form, Row, Table,} from "@govtechsg/sgds-react";
import {Accordion} from "@govtechsg/sgds-react/Accordion";
import {getCodeTypeSasList} from "@/apis/sims/api";

function ResultDetails({data, studentDetail, isDisabled, activeKeys, handleToggleAccordion}) {

    const [OPoint, setOPoint] = useState(0);
    const [ITEPoint, setITEPoint] = useState(0);

    function countPoint(grade, type) {
        if (type === "I") {
            getCodeTypeSasList("63", grade)
                .then((resp) => {
                    setITEPoint(resp?.data?.data?.[0]?.description)
                })
        }

        if (type === "O") {
            let ccaPoints;
            if (['1', '2'].includes(grade)) {
                ccaPoints = '2';
            } else if (['3', '4', '5', '6'].includes(grade)) {
                ccaPoints = '1';
            } else {
                ccaPoints = '0';
            }
            setOPoint(ccaPoints)
        }
    }

    useEffect(() => {
        if (studentDetail?.ITECCAGrade !== "" || studentDetail?.ITECCAGrade !== null) {
            countPoint(studentDetail?.ITECCAGrade, "I")
        }

        if (studentDetail?.ECAGrade !== "" || studentDetail?.ECAGrade !== null) {
            countPoint(studentDetail?.ECAGrade, "O")
        }
    }, [studentDetail]);


    return (
        <React.Fragment>
            <div className="mt-4">
                <Accordion key={"7"}
                           activeKey={activeKeys}>
                    <Accordion.Item eventKey="7" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("7")}>
                            CWA
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>CWA</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={
                                                    studentDetail?.courseList?.length > 0
                                                        ? studentDetail?.courseList[0]?.cumWghtAvg
                                                        : "-"
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>GPA</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={
                                                    studentDetail?.courseList?.length > 0
                                                        ? studentDetail?.courseList[0]?.gpa
                                                        : "-"
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="mt-4">
                <Accordion key={"8"}
                           activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="8" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("8")}>
                            ITE
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>ITE Acad GPA</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.ITEAcadGPA ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>ITE CCA Grade</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.ITECCAGrade ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>ECA Points</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={ITEPoint}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="mt-4">
                <Accordion key={"9"}
                           activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="9" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("9")}>
                            GCE O-Level Results
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>'O' Level Aggregate</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.OLAGG}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>CCA Grade</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.ECAGrade}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>ECA Points</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={OPoint}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Table responsive>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>S/N</Table.HeaderCell>
                                                    <Table.HeaderCell>Year Taken</Table.HeaderCell>
                                                    <Table.HeaderCell>'O' Subject</Table.HeaderCell>
                                                    <Table.HeaderCell>Grade</Table.HeaderCell>
                                                    <Table.HeaderCell>Subject Name</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {studentDetail?.gceOList?.map((data, index) => {
                                                    return (
                                                        <Table.Row>
                                                            <Table.DataCell>{index + 1}</Table.DataCell>
                                                            <Table.DataCell>{data?.OYr}</Table.DataCell>
                                                            <Table.DataCell>{data?.OSubjId}</Table.DataCell>
                                                            <Table.DataCell>{data?.OGrade}</Table.DataCell>
                                                            <Table.DataCell>{data?.OSubjName}</Table.DataCell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="mt-4">
                <Accordion key={"10"} activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="10" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("10")}>
                            GCE A-Level Results
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>'A' Level Aggregate</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Table responsive>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>S/N</Table.HeaderCell>
                                                    <Table.HeaderCell>Year Taken</Table.HeaderCell>
                                                    <Table.HeaderCell>'A' Subject</Table.HeaderCell>
                                                    <Table.HeaderCell>Grade</Table.HeaderCell>
                                                    <Table.HeaderCell>Subject Name</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {studentDetail?.gceAList?.map((data, index) => {
                                                    return (
                                                        <Table.Row>
                                                            <Table.DataCell>{index + 1}</Table.DataCell>
                                                            <Table.DataCell>{data?.AYr}</Table.DataCell>
                                                            <Table.DataCell>{data?.ASubjId}</Table.DataCell>
                                                            <Table.DataCell>{data?.AGrade}</Table.DataCell>
                                                            <Table.DataCell>{data?.ASubjName}</Table.DataCell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </React.Fragment>
    );
}

export default ResultDetails;
