import React, {useEffect, useState} from "react";

import {Col, Form, Row,} from "@govtechsg/sgds-react";


function Awarded({data, studentDetail, isDisabled, studentAwardDetail}) {
    const [academicYear, setAcademicYear] = useState(null);

    // define variable
    const [awardType, setAwardType] = useState("-");
    const [award, setAward] = useState("-");
    const [yearAwarded, setYearAwarded] = useState("-");
    const [amountAwarded, setAmountAwarded] = useState("-");

    // define list

    const handleClickEdit = () => {
        setIsDisabled(!isDisabled);
    };

    const handleAcademicYearChange = (e) => {
        const newValue = e.target.value;
        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            setAcademicYear(newValue);
        }
    };

    useEffect(() => {
        if (studentAwardDetail?.studFinAssistList?.length > 0) {
            setAwardType(`${studentAwardDetail?.studFinAssistList[0]?.awardType} - ${studentAwardDetail?.studFinAssistList[0]?.awardTypeDesc}`)
            setAward(`${studentAwardDetail?.studFinAssistList[0]?.award} - ${studentAwardDetail?.studFinAssistList[0]?.awardDesc}`)
            setYearAwarded(`${studentAwardDetail?.studFinAssistList[0]?.yrAwarded}`)
            setAmountAwarded(`${studentAwardDetail?.studFinAssistList[0]?.amtAwarded}`)
        } else {
            setAwardType("-")
            setAward("-")
            setYearAwarded("-")
            setAmountAwarded("-")
        }
    }, [studentAwardDetail])

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Award Type<span className={`text-danger`}> *</span></Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    awardType
                                }
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Award<span className={`text-danger`}> *</span></Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    award
                                }
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Year Awarded</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    yearAwarded
                                }
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Amount Awarded</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    amountAwarded
                                }
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Awarded;
