import {Button, Col, Form, Row, Table} from "@govtechsg/sgds-react";
import Select from "react-select";
import {toast} from "react-toastify";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {getCodeTypeSasList, getStudentDetail, getStudentList, saveStudentFinassists,} from "@/apis/sims/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import CustomModal from "@/components/CustomModal";

export default function Individual() {
    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);

    const [items, setItems] = useState([]);

    const [applicationDetail, setApplicationDetail] = useState({});

    const [groupEdit, setGroupEdit] = useState({name: "", age: ""});

    const [downloadOption, setDownloadOptions] = useState("Download New");

    const [selectAll, setSelectAll] = useState(false);

    const [show, setShow] = useState(false);

    const [stundentList, setStudentList] = useState([]);
    const [searchStudentEvent, setSearchStudentEvent] = useState(null);
    const [searchStudent, setSearchStudent] = useState("");
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [retrieve, setRetrieve] = useState(false);
    const [awardTypeList, setAwardTypeList] = useState([]);
    const [awardList, setAwardList] = useState([]);
    const [indexToBeDeleted, setIndexToBeDeleted] = useState(null);
    const [isEditing, setIsEditing] = useState(false)

    const [isDisabled, setIsDisabled] = useState(false);

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setItems(items.map((item) => ({...item, checked: newSelectAll})));
        updateGroupEditForm(newSelectAll ? items : []);
    };

    const updateGroupEditForm = (selectedItems) => {
        if (selectedItems.length === 0) {
            setGroupEdit({name: "", age: "", role: ""});
            return;
        }
        const name = selectedItems.map((item) => item.name).join(", ");
        const age = selectedItems.map((item) => item.age).join(", ");
        const role = selectedItems.map((item) => item.role).join(", ");
        setGroupEdit({name, age, role});
    };

    const handleSelectItem = (id) => {
        const newItems = items.map((item) => {
            if (item.id === id) {
                return {...item, checked: !item.checked};
            }
            return item;
        });
        setItems(newItems);
        setSelectAll(newItems.every((item) => item.checked));
        updateGroupEditForm(newItems.filter((item) => item.checked));
    };

    const handleChange = (id, field, value) => {
        setItems((prev) =>
            prev.map((item, index) => {
                if (index === id) {
                    return {...item, [field]: value};
                }
                return item;
            })
        );
    };

    const handleDelete = (id) => {
        const {
            checked,
            editable,
            isEditing,
            awardLabel,
            award_typeLabel,
            award_typeValue,
            ..._items
        } = items[id];

        saveStudentFinassists([
            {..._items, action: "D", awardDesc: awardLabel},
        ]).then((res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                if (res?.data?.status == true) {
                    setShow(false);
                    setIsDisabled(false);
                    handleGetStudentDetail();
                    toast.success("Record deleted successfully");
                } else {
                    res?.data?.details?.map((contentErr) => {
                        setShow(false);
                        setIsDisabled(false);
                        toast.error(contentErr?.error);
                    });
                }
            }
        });
    };

    const handleEdit = (idx) => {
        setIsDisabled(!isDisabled);
        setItems(
            items.map((item, index) => {
                if (index === idx) {
                    return {...item, editable: !item.editable, isEditing: true};
                }
                return item;
            })
        );
    };

    const handleCancelEdit = (idx) => {
        setIsDisabled(!isDisabled);
        setIsEditing(false)

        if(items[idx].action === "I"){
            setItems((prevItems) => prevItems.filter((_, i) => i !== idx))
            return
        }

        setItems(
            items.map((item, index) => {
                if (index === idx) {
                    return {...item, editable: !item.editable, isEditing: false};
                }
                return item;
            })
        );

        handleGetStudentDetail();
    };

    const handleAddRow = () => {
        let isStillEditing = false;

        items.map((content) => {
            if (content?.editable == true) {
                isStillEditing = true;
            }
        });

        if (isStillEditing) {
            toast.info("Please complete your entry first");
            return;
        }
        setIsDisabled(!isDisabled);
        setItems((prevArr) => {
            const newArr = [...prevArr];

            const newObj = {
                adm_no: selectedStudent.value,
                award_type: "",
                award: "",
                year_awarded: 0,
                amt_awarded: 0,
                last_upd_id: sessionStorage.getItem("staffId")
                    ? sessionStorage.getItem("staffId")
                    : process.env.REACT_APP_LAST_UPD_ID,
                checked: false,
                editable: true,
                isEditing: false,
                action: "I",
            };

            newArr.unshift(newObj);

            return newArr;
        });
    };

    const handleGetStudents = (search = "") => {
        getStudentList({search, limit: "20"}).then((res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let data =
                    res?.data?.data?.map((item) => {
                        return {label: item.admNo + " - " + item.name, value: item.admNo};
                    }) || [];

                setStudentList(data);
            }
        });
    };

    const handleGetStudentDetail = () => {
        setRetrieve(false);
        if (selectedStudent?.value) {
            getStudentDetail(selectedStudent?.value, "").then((res) => {
                if (res?.response?.data?.message) {
                    if (
                        res?.response?.data?.message?.length > 0 &&
                        Array.isArray(res?.response?.data?.message)
                    ) {
                        res?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(res?.response?.data?.message);
                    }
                } else if (res?.response?.status === 404) {
                    toast.error("Data not found");
                } else {
                    setRetrieve(true);
                    let data = res?.data?.data[0]?.studFinAssistList || [];
                    setApplicationDetail(res?.data?.data[0]);
                    setItems(
                        data.map((item) => {
                            return {
                                adm_no: item.admNo,
                                award: item.award,
                                // awardDesc: item?.awardDesc || null,
                                award_type: item.awardType,
                                // awardTypeDesc: item?.awardTypeDesc || null,
                                amt_awarded: item.amtAwarded,
                                year_awarded: item.yrAwarded,
                                last_upd_id: sessionStorage.getItem("staffId")
                                    ? sessionStorage.getItem("staffId")
                                    : process.env.REACT_APP_LAST_UPD_ID,
                                action: "U",
                            };
                        })
                    );
                }
            });
            return;
        }
        toast.info("Please select student!");
    };

    const handleGetListAwardType = () => {
        getCodeTypeSasList("AWARDTYPECODE").then((res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let data = res?.data?.data || [];
                setAwardTypeList(
                    data.map((item) => {
                        return {
                            label: item.code + " - " + item.description,
                            value: item.code,
                            id: item.codetype,
                        };
                    })
                );
            }
        });
    };

    const handleGetListAward = (code, index) => {
        getCodeTypeSasList(code).then((res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let data = res?.data?.data || [];
                setAwardList(
                    data.map((item) => {
                        return {
                            label: item.code + " - " + item.description,
                            value: item.code,
                            originalLabel: item.description,
                        };
                    })
                );
            }
        });
    };

    const handleSave = (id) => {
        const {
            checked,
            editable,
            awardLabel,
            award_typeLabel,
            award_typeValue,
            ..._items
        } = items[id];

        const hasNullOrEmpty = [checked, editable, awardLabel, award_typeLabel, award_typeValue, ...Object.values(_items)]
            .some(value => value === null || value === '');

        if (hasNullOrEmpty) {
            toast.warning("Please fill out the field")
            return
        }

        saveStudentFinassists([{..._items, awardDesc: awardLabel}]).then(
            (res) => {
                if (res?.response?.data?.message) {
                    if (
                        res?.response?.data?.message?.length > 0 &&
                        Array.isArray(res?.response?.data?.message)
                    ) {
                        res?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(res?.response?.data?.message);
                    }
                } else if (res?.response?.status === 404) {
                    toast.error("Data not found");
                } else {

                    if (res?.data?.status == true) {
                        setIsDisabled(!isDisabled);
                        handleGetStudentDetail();
                        toast.success("Record updated successfully");
                    } else {
                        res?.data?.details?.map((contentErr) => {
                            toast.error(contentErr?.error);
                        });
                    }
                }
            }
        );
    };

    useEffect(
        function () {
            if (searchStudent === "") {
                handleGetStudents();
                return;
            }
            clearTimeout(searchStudentEvent);
            setSearchStudentEvent(
                setTimeout(function () {
                    handleGetStudents(searchStudent);
                }, 500)
            );
        },
        [searchStudent]
    );

    return (
        <Layout currentNav={"utilities"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Utilities</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Update Student Scholarship Detail
                </Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Update Student Scholarship Detail</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className="d-flex gap-4 flex-column">
                    <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                        <div className="row">
                            <div className="col">
                                <h3>Data Retrieval</h3>
                            </div>
                        </div>

                        <Form
                            ref={contentRef}
                            className={` d-flex flex-column gap-3 collapse-animation`}
                            style={{
                                overflow: isOpen ? "unset" : "hidden",
                                maxHeight: isOpen
                                    ? `${
                                        contentRef?.current?.scrollHeight == null
                                            ? "100%"
                                            : contentRef?.current?.scrollHeight
                                    }px`
                                    : "0px",
                            }}
                            onSubmit={() => console.log("a")}
                        >
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Adm. No.</Form.Label>
                                        <Select
                                            options={stundentList}
                                            placeholder={`Students`}
                                            onInputChange={setSearchStudent}
                                            onChange={(e) => {
                                                setSelectedStudent(e);
                                                setItems([]);
                                                setRetrieve(false);
                                            }}
                                            value={selectedStudent}
                                        />
                                    </Form.Group>
                                </Col>
                                {/*<Col xs={3}>*/}
                                {/*    <Form.Group>*/}
                                {/*        <Form.Label>Admission No.</Form.Label>*/}
                                {/*        <Select options={options} />*/}
                                {/*    </Form.Group>*/}
                                {/*</Col>*/}
                                {/* <Col xs={3}>
                                      <Form.Group>
                                        <Form.Label>ID No. </Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                      <Form.Group>
                                        <Form.Label>Study Stage</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                      <Form.Group>
                                        <Form.Label>Study Status</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                      <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                      <Form.Group>
                                        <Form.Label>Course</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </Col> */}
                            </Row>

                            <Row>
                                <Col xs={3}>
                                    <Button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            handleGetListAwardType();
                                            handleGetStudentDetail();
                                        }}
                                    >
                                        Retrieve
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="btn-clear btn-link ps-0 mt-3 d-flex gap-2 align-items-center"
                        >
                            {isOpen ? "Hide " : "Expand "}{" "}
                            {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                        </button>
                    </div>
                </div>

                {retrieve === true && (
                    <Row className={`justify-content-end`}>
                        <Col md={12} className="d-flex gap-3">
                            <Button
                                variant={`outline-dark`}
                                onClick={handleAddRow}
                                className={`d-flex gap-3`}
                            >
                                <i className="bi bi-plus-circle"></i>
                                Add
                            </Button>
                        </Col>

                        <Col xs={12}>
                            <Table responsive>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Course List</Table.HeaderCell>
                                        <Table.HeaderCell>Stud Status</Table.HeaderCell>
                                        <Table.HeaderCell>Study Stage</Table.HeaderCell>
                                        <Table.HeaderCell>Award Type</Table.HeaderCell>
                                        <Table.HeaderCell>Award</Table.HeaderCell>
                                        <Table.HeaderCell>Year of Award</Table.HeaderCell>
                                        <Table.HeaderCell>Award Amount</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {items.map((item, index) => (
                                        <Table.Row key={index}>
                                            <Table.DataCell style={{minWidth: "200px"}}>
                                                {applicationDetail?.courseList?.length > 0
                                                    ? applicationDetail?.courseList[0]?.courseName
                                                    : "-"}
                                            </Table.DataCell>
                                            <Table.DataCell style={{minWidth: "200px"}}>
                                                {applicationDetail?.courseList?.length > 0
                                                    ? applicationDetail?.courseList[0]?.studStatus
                                                    : "-"}
                                            </Table.DataCell>
                                            <Table.DataCell style={{minWidth: "200px"}}>
                                                {applicationDetail?.courseList?.length > 0
                                                    ? applicationDetail?.courseList[0]?.studyStage
                                                    : "-"}
                                            </Table.DataCell>

                                            <Table.DataCell style={{minWidth: "200px"}}>
                                                {item.editable ?
                                                    item?.isEditing ?
                                                        (
                                                            awardTypeList?.filter(
                                                                (content) => item?.award_type == content?.value
                                                            )[0]?.label || item.award_type
                                                        )
                                                        : <Select
                                                            placeholder="Select Type"
                                                            menuPortalTarget={document.body}
                                                            value={{
                                                                label: awardTypeList?.filter(
                                                                    (content) => item?.award_type == content?.value
                                                                ).length
                                                                    ? awardTypeList?.filter(
                                                                        (content) =>
                                                                            item?.award_type == content?.value
                                                                    )[0]?.label
                                                                    : "",
                                                                id: item.award_type,
                                                                value: awardTypeList?.filter(
                                                                    (content) => item?.award_type == content?.value
                                                                ).length
                                                                    ? awardTypeList?.filter(
                                                                        (content) =>
                                                                            item?.award_type == content?.value
                                                                    )[0]?.value
                                                                    : "",
                                                            }}
                                                            options={awardTypeList}
                                                            onChange={(e) => {
                                                                handleGetListAward(e.value, index);
                                                                handleChange(index, "award_type", e.value);
                                                                handleChange(index, "awardTypeDesc", e.label);
                                                                handleChange(index, "award_typeValue", e.value);
                                                                handleChange(index, "award_typeLabel", e.label);
                                                            }}
                                                        /> : (
                                                        awardTypeList?.filter(
                                                            (content) => item?.award_type == content?.value
                                                        )[0]?.label || item.award_type
                                                    )}
                                            </Table.DataCell>
                                            <Table.DataCell style={{minWidth: "200px"}}>
                                                {item.editable ?
                                                    item?.isEditing ?
                                                        (
                                                            item.awardDesc || item.award
                                                        )
                                                        :
                                                        (
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                placeholder="Select Award"
                                                                options={awardList}
                                                                value={{
                                                                    label:
                                                                        item.awardDesc !== undefined
                                                                            ? item.award + " - " + item.awardDesc
                                                                            : "",
                                                                    value: item.award,
                                                                }}
                                                                onChange={(e) => {
                                                                    handleChange(index, "award", e.value);
                                                                    handleChange(index, "awardDesc", e.originalLabel);
                                                                    handleChange(
                                                                        index,
                                                                        "awardLabel",
                                                                        e.originalLabel
                                                                    );
                                                                }}
                                                            />
                                                        ) : (
                                                        item.awardDesc || item.award
                                                    )}
                                            </Table.DataCell>
                                            <Table.DataCell>
                                                {item.editable ?
                                                    item?.isEditing ?
                                                        (
                                                            item.year_awarded
                                                        )
                                                        :
                                                        (
                                                            <input
                                                                type="text"
                                                                value={item.year_awarded}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        index,
                                                                        "year_awarded",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                        item.year_awarded
                                                    )}
                                            </Table.DataCell>
                                            <Table.DataCell>
                                                {item.editable ? (
                                                    <input
                                                        type="number"
                                                        value={item.amt_awarded}
                                                        onChange={(e) =>
                                                            handleChange(index, "amt_awarded", e.target.value)
                                                        }
                                                    />
                                                ) : (
                                                    item.amt_awarded
                                                )}
                                            </Table.DataCell>
                                            <Table.DataCell>
                                                <div className={`d-flex gap-1`}>
                                                    {item.editable ? (
                                                        <React.Fragment>
                                                            <button
                                                                className={`btn-clear text-green-500`}
                                                                onClick={() => handleSave(index)}
                                                            >
                                                                <i className={`bi bi-check-lg`}></i> Save
                                                            </button>
                                                            <button
                                                                className={`btn-clear text-red-500`}
                                                                onClick={() => handleCancelEdit(index)}
                                                            >
                                                                <i className={`bi bi-x-circle`}></i>
                                                                {` `}
                                                                Cancel
                                                            </button>
                                                        </React.Fragment>
                                                    ) : (
                                                        <>
                                                            <button
                                                                disabled={isDisabled}
                                                                className={`btn-clear`}
                                                                onClick={() => {
                                                                    handleEdit(index);
                                                                    handleGetListAward(
                                                                        awardTypeList?.filter(
                                                                            (content) =>
                                                                                item?.award_type == content?.value
                                                                        )[0]?.value
                                                                    );
                                                                }}
                                                            >
                                                                <i className={`bi bi-pencil`}></i>
                                                            </button>
                                                        </>
                                                    )}
                                                    <button
                                                        className={`btn-clear`}
                                                        disabled={
                                                            index == 0
                                                                ? item?.awardType !== ""
                                                                    ? isDisabled
                                                                    : false
                                                                : isDisabled
                                                        }
                                                        onClick={() => {
                                                            setShow(true);
                                                            setIndexToBeDeleted(index);
                                                        }}
                                                    >
                                                        <i className={`bi bi-trash`}></i>
                                                    </button>
                                                </div>
                                            </Table.DataCell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Col>
                    </Row>
                )}
                <CustomModal
                    show={show}
                    handleClose={() => setShow(false)}
                    action={() => handleDelete(indexToBeDeleted)}
                    title={`Delete award student`}
                    description={`System will delete award student. Do you wish to proceed?`}
                />
            </sgds-content-body>
        </Layout>
    );
}
