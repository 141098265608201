import React, {useEffect, useState} from "react";

import {Col, Form, Row} from "@govtechsg/sgds-react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

import {getOccupationList} from "@/apis/preparation/setup-occupation/api";

const statusOptions = [
    {
        label: "Living in the same household",
        value: "L",
    },
    {
        label: "Not living in the same household",
        value: "NL",
    },
    {
        label: "Deceased",
        value: "DE",
    },
    {
        label: "Divorced. Living with applicant",
        value: "DL",
    },
    {
        label: "Divorced. Not living with applicant",
        value: "DN",
    },
    {
        label: "In-Jail",
        value: "IJ",
    },
];

function Mother({
                    data,
                    listParent,
                    setListParent,
                    studentDetail,
                    isDisabled,
                    handleParentChange,
                    getTotalSumIncome,
                    motherIndex,
                    isReadOnly = false,
                    enabler,
                    isRecalculated,
                    setIsRecalculated
                }) {
    const [statusOccupationList, setStatusOccupationList] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState({
        label: "",
        value: "",
    });
    const [selectedOccupation, setSelectedOccupation] = useState({
        label: "",
        value: "",
    });

    useEffect(() => {
        if (listParent?.length > 0) {
            listParent?.map((item, index) => {
                if (listParent?.[motherIndex]?.relationship?.includes('M')) {
                    statusOptions.map((content) => {
                        if (listParent?.[motherIndex]?.status == content.value) {
                            setSelectedStatus({
                                label: content.label,
                                value: content.value,
                            });
                        }
                    });

                    statusOccupationList.map((content) => {
                        if (
                            listParent[motherIndex]?.faas_occupations?.hasOwnProperty("id")
                        ) {
                            if (
                                listParent[motherIndex]?.faas_occupations?.id ===
                                content?.value
                            ) {
                                setSelectedOccupation({
                                    label: content.label,
                                    value: content.value,
                                    isIncomeContribute: content.is_income_contribute,
                                    isRemarkRequired: content.is_remark_required,
                                });
                            }
                        } else {
                            if (
                                listParent[motherIndex]?.faas_occupations == content?.value
                            ) {
                                setSelectedOccupation({
                                    label: content.label,
                                    value: content.value,
                                    isIncomeContribute: content.is_income_contribute,
                                    isRemarkRequired: content.is_remark_required,
                                });
                            }
                        }
                    });
                }
            });
        }
    }, [listParent]);

    useEffect(() => {
        getOccupationList(0, 1, "N").then((resp) => {
            if (resp?.status == 200) {
                let contentArray = [];
                resp?.data?.data?.map((content) => {

                    contentArray.push({
                        label: content.occupation,
                        value: content.id,
                        is_income_contribute: content.income_contribution,
                        is_remark_required: content?.remark,
                    });

                });
                setStatusOccupationList(contentArray);
            }
        });
    }, []);

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={enabler ? isReadOnly ? true : listParent?.length === 0 : true}
                                value={listParent[motherIndex]?.name ?? ""}
                                onChange={(e) => {
                                    handleParentChange("name", e.target.value, motherIndex);
                                }}
                                className={`text-uppercase`}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <Select
                                options={statusOptions}
                                isDisabled={enabler ? isReadOnly ? true : listParent?.length === 0 : true}
                                value={selectedStatus}
                                onChange={(selectedOption) => {
                                    setSelectedStatus({
                                        label: selectedOption.label,
                                        value: selectedOption.value,
                                    });
                                    handleParentChange(
                                        "status",
                                        selectedOption.value,
                                        motherIndex
                                    );
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>NRIC / Passport No</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={enabler ? isReadOnly ? true : listParent?.length === 0 : true}
                                required={selectedStatus?.value == "DL" ? true : false}
                                value={listParent[motherIndex]?.id_no ?? ""}
                                onChange={(e) => {
                                    handleParentChange("id_no", e.target.value, motherIndex)
                                }
                                }
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group className="d-flex flex-column">
                            <Form.Label>Birth date</Form.Label>
                            <DatePicker
                                className={`form-control`}
                                maxDate={new Date()}
                                selected={listParent[motherIndex]?.birthdate == undefined || listParent[motherIndex]?.birthdate == null ? null : listParent[motherIndex]?.birthdate}
                                onChange={(date) => {
                                    if (date == null) {
                                        handleParentChange("birthdate", "", motherIndex);
                                        handleParentChange("age", 0, motherIndex);
                                    } else {
                                        const choosenDate = moment(date).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        );
                                        handleParentChange("birthdate", choosenDate, motherIndex);
                                        handleParentChange(
                                            "age",
                                            moment().diff(choosenDate, "years"),
                                            motherIndex
                                        );
                                    }
                                }}
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                disabled={
                                enabler ?
                                    isReadOnly ? true :
                                        listParent?.length === 0 ||
                                        selectedStatus?.value == "DE" : true
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={listParent[motherIndex]?.age ?? ""}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Occupation</Form.Label>
                            <Select
                                options={statusOccupationList}
                                isDisabled={
                                    enabler ?
                                    isReadOnly ? true :
                                        listParent?.length === 0 ||
                                        selectedStatus?.value == "DE" ||
                                        selectedStatus?.value == "IJ" : true
                                }
                                value={selectedOccupation}
                                onChange={(selectedOption) => {
                                    handleParentChange(
                                        "faas_occupations",
                                        selectedOption.value,
                                        motherIndex
                                    );

                                    setSelectedOccupation({
                                        label: selectedOption.label,
                                        value: selectedOption.value,
                                        isIncomeContribute: selectedOption.is_income_contribute,
                                        isRemarkRequired: selectedOption.is_remark_required,
                                    });
                                    if (selectedOption.is_income_contribute != "Y") {
                                        handleParentChange("mthly_income_contrib", 0, motherIndex);
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Other Income</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={enabler ? isReadOnly ? true : listParent?.length === 0 : true}
                                value={listParent[motherIndex]?.other_income ?? ""}
                                onChange={(e) => {
                                    handleParentChange(
                                        "other_income",
                                        e.target.value,
                                        motherIndex
                                    );
                                    setIsRecalculated(true)
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Gross Monthly Income</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={
                                enabler ?
                                    isReadOnly ? true :
                                        listParent?.length === 0 ||
                                        selectedOccupation.isIncomeContribute != "Y" ||
                                        selectedStatus?.value == "DE" ||
                                        selectedStatus?.value == "DN" ||
                                        selectedStatus?.value == "IJ" : true
                                }
                                value={listParent[motherIndex]?.mthly_income_contrib ?? ""}
                                onChange={(e) => {
                                    handleParentChange(
                                        "mthly_income_contrib",
                                        e.target.value,
                                        motherIndex
                                    );
                                    setIsRecalculated(true)
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>

                    {/*<Col xs={6} className="">*/}
                    {/*    <Form.Group>*/}
                    {/*        <Form.Label>Child Support</Form.Label>*/}
                    {/*        <Form.Control*/}
                    {/*            type={`text`}*/}
                    {/*            disabled={*/}
                    {/*                enabler ?*/}
                    {/*                    isReadOnly ? true :*/}
                    {/*                        listParent?.length === 0 ||*/}
                    {/*                        selectedOccupation.isIncomeContribute != "Y" ||*/}
                    {/*                        selectedStatus?.value == "DE" ||*/}
                    {/*                        selectedStatus?.value == "DN" ||*/}
                    {/*                        selectedStatus?.value == "IJ" : true*/}
                    {/*            }*/}
                    {/*            value={listParent[motherIndex]?.child_support ?? ""}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                handleParentChange(*/}
                    {/*                    "child_support",*/}
                    {/*                    e.target.value,*/}
                    {/*                    motherIndex*/}
                    {/*                );*/}

                    {/*            }}*/}
                    {/*        ></Form.Control>*/}
                    {/*    </Form.Group>*/}
                    {/*</Col>*/}

                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={
                                    enabler ?
                                        isReadOnly ? true :
                                        listParent?.length === 0 ||
                                        selectedOccupation.isRemarkRequired != "Y" ||
                                        selectedOccupation.isRemarkRequired != "Y" ||
                                        selectedStatus?.value !== "NL" : true
                                }
                                required={selectedStatus?.value == "NL" ? true : false}
                                value={listParent[motherIndex]?.remark?.replace(/\r?\n/g, " ") ?? ""}
                                onChange={(e) => {
                                    handleParentChange("remark", e.target.value, motherIndex);
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Mother;
