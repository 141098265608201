import {apexAxiosCall, apiCall} from "@/utils/apiCall";
import {createUrlWithParams} from "@/components/utils/utils";

export async function getAdmNo(ACAD_YR, EXERCISE) {
    let params = {
        limit: 20
    };
    if (ACAD_YR !== null && ACAD_YR !== undefined) {
        params = {
            ...params,
            acad_yr: ACAD_YR,
        };
    }
    if (EXERCISE !== null && EXERCISE !== undefined) {
        params = {
            ...params,
            exercise: EXERCISE,
        };
    }

    let baseUrl = process.env.REACT_APP_API_URL + `/applications`;
    const url = createUrlWithParams(baseUrl, params);

    return apiCall(url, {}, "GET");
}

export async function getApplication(
    ACAD_YR,
    EXERCISE,
    ADM_NO,
    VERIFY_STATUS,
    ORDERBYFIELD,
    IS_CHECKED_NOT_ENROLLED = false,
    page = 1,
    limit = 20,
    orderDirection = 'ASC',
    APPLN_STATUS_NULL = false
) {

    let params = {
        isAnalytic: true,
        limit: limit,
        page: page,
        appln_status_not_equals: "D",
    };
    if (ACAD_YR !== null && ACAD_YR !== "" && ACAD_YR !== undefined) {
        params = {
            ...params,
            acad_yr: ACAD_YR,
        };
    }
    if (EXERCISE !== null && EXERCISE !== "" && EXERCISE !== undefined) {
        params = {
            ...params,
            exercise: EXERCISE,
        };
    }
    if (ADM_NO !== null && ADM_NO !== "" && ADM_NO !== undefined) {
        params = {
            ...params,
            adm_no: ADM_NO,
        };
    }

    if (VERIFY_STATUS !== "" && VERIFY_STATUS !== undefined) {
        params = {
            ...params,
            verify_status: VERIFY_STATUS,
        };
    }

    if (ORDERBYFIELD !== null && ORDERBYFIELD !== "" && ORDERBYFIELD !== undefined) {
        params = {
            ...params,
            orderByField: ORDERBYFIELD,
        };
    }

    if (orderDirection !== null && orderDirection !== "" && orderDirection !== undefined) {
        params = {
            ...params,
            orderByDirection: orderDirection,
        };
    }

    if (APPLN_STATUS_NULL) {
        params = {
            ...params,
            appln_status: null,
        };
    }


    let baseUrl = process.env.REACT_APP_API_URL + `/applications`;
    const url = createUrlWithParams(baseUrl, params);

    return apiCall(url, {}, "GET");
}

export async function postStudentList(student_ids, is_not_enrolled = false, sort = "id_no", exception = false) {
    // let studStatus = "E";
    let studStatus = "";
    if (is_not_enrolled || exception) {
        studStatus = "";
    }

    return apexAxiosCall(
        process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getStudentList",
        {
            student_id: student_ids,
            studstatus: studStatus,
            course_tag: "Y",
            transfer_tag: "Y",
            page: 0,
            limit: 0,
            search: "",
            sort: sort,
        }
    );
}

export async function patchApplication(applicationData) {
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications`,
        {
            data: Array.isArray(applicationData)
                ? applicationData
                : [applicationData],
        },
        "PATCH"
    );
}

export async function deleteApplication(ACAD_YR, EXERCISE, ADM_NO) {
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}`,
        {},
        "DELETE"
    );
}
